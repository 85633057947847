<template>
  <div
    class="
      max-w-3xl
      px-4
      mx-auto
      sm:px-6
      lg:max-w-7xl
      lg:px-8
      bg-white
      rounded-md
    "
  >
    <div
      class="py-8"
      ref="jitsiContainer"
      style="height: 100%; width: 100%"
    ></div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadScript("https://meet.jit.si/external_api.js", () => {
      if (!window.JitsiMeetExternalAPI)
        throw new Error("Jitsi Meet API not loaded");
      this.embedJitsiWidget();
    });
  },
  created() {
    this.roomName = this.$route.params.roomName;
  },
  beforeUnmount() {
    this.removeJitsiWidget();
  },
  methods: {
    loadScript(src, cb) {
      const scriptEl = document.createElement("script");
      scriptEl.src = src;
      scriptEl.async = 1;
      document.querySelector("head").appendChild(scriptEl);
      scriptEl.addEventListener("load", cb);
    },
    embedJitsiWidget() {
      this.options.roomName = this.roomName;
      const options = {
        ...this.options,
        parentNode: this.$refs.jitsiContainer,
      };
      this.jitsiApi = new window.JitsiMeetExternalAPI("meet.jit.si", options);
    },
    executeCommand(command, ...value) {
      console.log(command);
      this.jitsiApi.executeCommand(command, ...value);
    },
    addEventListener(event, fn) {
      this.jitsiApi.on(event, fn);
    },
    // Misc
    removeJitsiWidget() {
      if (this.jitsiApi) this.jitsiApi.dispose();
    },
  },
  data() {
    return {
      options: {
        roomName: "",
        width: "100%",
        height: 600,
        configOverwrite: {
          remoteVideoMenu: {
            disableKick: true,
          },
          toolbarButtons: [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "embedmeeting",
            "fullscreen",
            "fodeviceselection",
            "hangup",
            "profile",
            "chat",
            "recording",
            "livestreaming",
            "etherpad",
            "sharedvideo",
            "shareaudio",
            "settings",
            "raisehand",
            "videoquality",
            "filmstrip",
            "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "select-background",
            "download",
            "help",
            "mute-everyone",
            "mute-video-everyone",
            "security",
          ],
        },
      },
    };
  },
};
</script>
