<template>
  <div>
    <Jitsi :roomName="'trevira-event-room-1-xdfwerdcs'" />
  </div>
</template>

<script>
import Jitsi from "@/components/Jitsi";

export default {
  name: "Event",
  components: {
    Jitsi,
  },
};
</script>
